import React from 'react';

import styled from 'styled-components';

import Layout from '../components/layout';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 100%;
  min-height: 700px;

  h2 {
    margin-bottom: 2rem;
  }

  section {
    margin-bottom: 2rem;
  }
  div {
    margin-bottom: 1.6rem;
  }
`;

export default function Scholarship() {
  return (
    <Layout>
      <Container>
        <section>
          <h2>Abraham H. Howland, Jr. Lodge Scholarship</h2>
          <div>
            Planning to attend an accredited institution of higher
            learning--including 2 and 4 year colleges, technical institutes, and
            trade schools?
          </div>
          <div>
            Eligible applicants are:
            <ul>
              <li>Less than 23 years old</li>
              <li>
                A child or grandchild of a living or deceased member of this
                lodge (including members&apos; adopted children and
                stepchildren, as well as children of those adopted children or
                stepchildren)
              </li>
              <li>
                A member of Phoenix Chapter, Order of DeMolay, or New Bedford
                Assembly #3, Order of Rainbow for Girls
              </li>
            </ul>
          </div>
          <div>
            Our scholarship deadline has passed. Please check back in April 2021
            for the 2021-2022 scholarship application.
          </div>
        </section>
        <section>
          <h2>Grand Lodge Scholarship</h2>
          <div>
            Educational grants are available to Massachusetts Masons, their
            children, and grandchildren pursuing a full-time, undergraduate
            degree in an accredited educational institution. Eligibility for
            scholarship grants is based on a combination of academic standing
            and achievements, broad participation in community and/or school
            activities, and financial need.
          </div>
          <div>
            <a href="https://massfreemasonry.org/community-programs/grand-lodge-scholarship-program/">
              Click here to apply!
            </a>
          </div>
        </section>
      </Container>
    </Layout>
  );
}
